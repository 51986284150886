/* eslint-disable @typescript-eslint/no-explicit-any */

export type UserPartial = {
  readonly id: string;
  readonly username: string;
  readonly enabled: boolean;
  readonly email: string;
  readonly emailVerified: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly createdTimestamp: number;
  readonly attributes: { readonly [attributeName: string]: ReadonlyArray<string> };
};

export type User = UserPartial & {
  readonly [key: Exclude<string, keyof UserPartial>]: any;
};
