import * as React from "react";

export function useClickOutside<T>(element: React.RefObject<T>, onClickOutside: () => void): void {
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClick = (event: any): void => {
      // TODO: type of current
      if (element.current && !(element.current as unknown as HTMLDivElement).contains(event.target)) {
        onClickOutside();
      }
    };
    document.addEventListener("mousedown", onClick);
    return () => {
      document.removeEventListener("mousedown", onClick);
    };
  }, [element]);
}
