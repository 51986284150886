import { IconName, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export interface IconProps {
  readonly onClick?: () => void;
  readonly icon: IconName;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly colorClass?: string;
  readonly message?: string;
  readonly size?: SizeProp;
}

export function Icon(props: IconProps): React.ReactElement<IconProps> {
  const className = `${props.className ? props.className : "inline-block text-md"}
    ${props.disabled ? "" : "cursor-pointer"} 
    ${props.disabled ? "text-gray-400" : props.colorClass ?? ""} `;
  const size = props.size || "lg";
  return props.disabled ? (
    <span className={className} title={props.message}>
      <FontAwesomeIcon icon={["fas", props.icon]} size={size} />
    </span>
  ) : (
    <a
      className={className}
      onClick={() => {
        if (!props.disabled && props.onClick) {
          props.onClick();
        }
      }}
      title={props.message}
    >
      <FontAwesomeIcon icon={["fas", props.icon]} size={size} />
    </a>
  );
}
