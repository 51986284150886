import * as React from "react";

export type AlertType = "neutral" | "data" | "info" | "warning" | "error" | "success" | "danger";

export interface AlertProps {
  readonly type: AlertType;
  readonly close?: () => void;
  readonly children?: React.ReactNode;
}

export function Alert(props: AlertProps): React.ReactElement<AlertProps> {
  const { type, close, children } = props;
  const icon = undefined; //getIcon(type); // TODO: fix icon
  let className = "p-2 inline-block";
  switch (type) {
    case "neutral":
      className += " text-gray-700 bg-gray-100";
      break;
    case "data":
      className += " text-gray-700 bg-gray-100";
      break;
    case "info":
      className += " text-gray-700 bg-gray-100";
      break;
    case "warning":
      className += " text-orange-700 bg-orange-100";
      break;
    case "error":
      className += " text-red-700 bg-red-100";
      break;
    case "danger":
      className += " text-red-700 bg-red-100";
      break;
    case "success":
      className += " text-green-700 bg-green-100";
      break;
    default:
      break;
  }
  return (
    <div>
      <div className={className}>
        {icon && <i className={icon} />} <div className="flex flex-col space-y-2 ">{children}</div>
        {close ? (
          <div>
            <i className="fa fa-close" onClick={() => close()} />
          </div>
        ) : undefined}
      </div>
    </div>
  );
}

export function getIcon(type: AlertType): string | undefined {
  switch (type) {
    case "neutral":
      return undefined;
    case "data":
      return "fa fa-table";
    case "info":
      return "fa fa-info";
    case "warning":
      return "fa fa-exclamation-triangle";
    case "error":
      return "fa fa-frown-o";
    case "success":
      return "fa fa-info";
    default:
      throw new Error("Unknown AlertType");
  }
}
