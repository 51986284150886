import { OidcEffectManager } from "./effect-managers";
import { clientConfig } from "./client-config";

const serverUri = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

export const userManagerSettings: OidcEffectManager.UserManagerSettings = {
  authority: clientConfig.oidc_login_url,
  client_id: clientConfig.oidc_client_id,
  redirect_uri: `${serverUri}/${clientConfig.server_path_prefix}/login-callback`,
  response_type: "id_token token",
  scope: "openid profile email",
  silent_redirect_uri: `${serverUri}/silent-renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: false,
  post_logout_redirect_uri: clientConfig.oidc_post_logout_url,
};
