import { Cmd } from "@typescript-tea/core";
import { ctorsUnion, CtorsUnion } from "ctors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { User } from "@ka/shared";
import { HttpEffectManager, NavigationEffectManager as Navigation, OidcEffectManager } from "../../effect-managers";
import * as Route from "../../route";
import * as Routes from "../../routes";
import { setUser } from "../../sentry";
import { userManagerSettings } from "../../user-manager-settings";
import * as Admin from "../admin";
import { clientConfig } from "../../client-config";

export type State = ErrorState | WaitingForUserSessionState | LoggedInState | LoggedOutState;

//const defaultRealm = "Users";

export type LoggedInState = {
  readonly type: "LoggedInState";
  readonly activeUser: User.ActiveUser;
  readonly urlMatch: Route.UrlMatch<Routes.RootLocation> | undefined;
  readonly adminState: Admin.State | undefined;
  readonly waitingForResponse: boolean;
  readonly errorResponse: boolean;
};

export type WaitingForUserSessionState = {
  readonly type: "WaitingForUserSessionState";
  readonly urlPath: string;
  readonly urlQuery: string;
};

export type LoggedOutState = {
  readonly type: "LoggedOutState";
};

type RedirectState = { readonly redirectUrl: string | undefined };

export type ErrorState = {
  readonly type: "ErrorState";
  readonly reason: string;
};

export const Action = ctorsUnion({
  UrlChanged: (url: Navigation.Url) => ({ url }),
  UrlRequested: (urlRequest: Navigation.UrlRequest) => ({ urlRequest }),
  HttpStateChanged: (httpState: HttpEffectManager.HttpState) => ({ httpState }),
  UserSessionChanged: (user: OidcEffectManager.User | undefined) => ({ user }),
  AccessTokenRefreshed: (user: OidcEffectManager.User) => ({ user }),
  DispatchAdmin: (action: Admin.Action) => ({ action }),
  Logout: () => ({}),
  NoOp: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function init(url: Navigation.Url): readonly [State, Cmd<Action>?] {
  const initialUrl = url.path + (url.query ?? "");
  const urlMatch = Routes.parseUrl(initialUrl);

  if (
    !urlMatch ||
    (urlMatch.location.type === "AdminLocation" && urlMatch.location.urlPrefix !== clientConfig.server_path_prefix)
  ) {
    return [{ type: "ErrorState", reason: "Page not found. Correct format: host/prefix" }];
  }

  if (urlMatch.location.type !== "LoginCallback" && urlMatch.location.type !== "LoggedOut") {
    // Since this is the init() function we never have a user in our state at this point,
    // so the only thing we can do is to try to login which will either result in a user being
    // found directly (becuase we were already have a token in local storage), or a redirect to the login server
    // If we are already logged in we will have our user session subscription triggered.
    // If we are nog logged in then we will be redirected to the login server.
    // Use the current url as the state to save in the redirect round-trip
    const redirectState: RedirectState = { redirectUrl: initialUrl };

    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },

      OidcEffectManager.login(userManagerSettings, redirectState),
    ];
  }

  if (urlMatch.location.type === "LoginCallback") {
    // We got the login callback, let's process it and if successful the subscription will get a user session
    return [
      {
        type: "WaitingForUserSessionState",
        urlPath: url.path,
        urlQuery: url.query || "",
      },
      OidcEffectManager.processSigninCallback(userManagerSettings),
    ];
  }

  // User logged out and was redirected to our application
  if (urlMatch.location.type === "LoggedOut") {
    return [{ type: "LoggedOutState" }];
  }

  // Should never get here
  return exhaustiveCheck(urlMatch.location, true);
}

export function update(action: Action, state: State): readonly [State, Cmd<Action>?] {
  if (state.type === "ErrorState") {
    return [state];
  }
  switch (action.type) {
    case "Logout": {
      return [{ type: "LoggedOutState" }, OidcEffectManager.logout()];
    }
    case "AccessTokenRefreshed": {
      if (state.type !== "LoggedInState") {
        return [state];
      }
      const { user } = action;
      const activeUser = User.buildActiveUser(user, user.access_token);
      if (!User.isValidUser(activeUser)) {
        return [
          {
            ...state,
            type: "ErrorState",
            reason: activeUser.reason,
          },
        ];
      }
      return [{ ...state, activeUser: activeUser }];
    }
    case "UserSessionChanged": {
      const { user } = action;
      switch (state.type) {
        case "LoggedInState": {
          // If we have no user then set state as logged out
          if (user === undefined) {
            return [{ type: "LoggedOutState" }];
          }
          return [state];
        }
        case "WaitingForUserSessionState": {
          //If we got an undefind user then there was some error in the login flow
          if (user === undefined) {
            return [{ type: "ErrorState", reason: "OIDC user is undefined" }];
          }
          const activeUser = User.buildActiveUser(user, user.access_token);
          if (!User.isValidUser(activeUser)) {
            return [
              {
                ...state,
                type: "ErrorState",
                reason: activeUser.reason,
              },
            ];
          }

          // Set active user for sentry reporting, side-effect in reducer, not nice!!
          setUser(activeUser.email);

          const redirectState = user.state as RedirectState;
          const originalUrl =
            redirectState && redirectState.redirectUrl ? redirectState.redirectUrl : state.urlPath + state.urlQuery;
          return [
            {
              type: "LoggedInState",
              activeUser,
              urlMatch: undefined,
              adminState: undefined,
              waitingForResponse: false,
              errorResponse: false,
            },
            Navigation.replaceUrl(originalUrl),
          ];
        }
        case "LoggedOutState": {
          // Once logged out, anything else that happens is in error
          return [{ type: "ErrorState", reason: "LoggedOutState" }];
        }
        default:
          return exhaustiveCheck(state, true);
      }
    }

    case "UrlChanged": {
      switch (state.type) {
        case "LoggedInState": {
          const urlMatch = Routes.parseUrl(action.url.path + (action.url.query ?? ""));

          if (!urlMatch) {
            return [{ type: "ErrorState", reason: "Not found" }];
          }

          const cmds = [];

          const newState = { ...state, urlMatch };
          switch (urlMatch.location.type) {
            case "LoginCallback":
              // LoginCallback can only be triggered in init() as it starts the application
              return [{ type: "ErrorState", reason: "LoginCallback error" }];
            case "LoggedOut":
              return [{ type: "LoggedOutState" }];

            case "AdminLocation": {
              if (urlMatch.location.urlPrefix !== clientConfig.server_path_prefix) {
                return [{ type: "ErrorState", reason: "Not found" }];
              }
              const [adminState, mainCmd] = Admin.init(urlMatch.location.urlPrefix, state.activeUser, state.adminState);
              cmds.push(Cmd.map(Action.DispatchAdmin, mainCmd));
              return [{ ...newState, urlMatch, adminState }, Cmd.batch(cmds)];
            }
            default:
              return exhaustiveCheck(urlMatch.location, true);
          }
        }

        default:
          // In other states this action has no relevance
          return [state];
      }
    }

    case "UrlRequested":
      switch (action.urlRequest.type) {
        case "InternalUrlRequest":
          return [state, Navigation.pushUrl(action.urlRequest.url)];
        case "ExternalUrlRequest":
          return [state, Navigation.load(Navigation.toString(action.urlRequest.url))];
        default:
          return exhaustiveCheck(action.urlRequest);
      }

    case "HttpStateChanged": {
      if (state.type !== "LoggedInState") {
        return [state];
      }

      let newState = state;
      if (action.httpState === "waiting") {
        newState = {
          ...state,
          waitingForResponse: true,
        };
      }
      if (action.httpState === "idle") {
        newState = {
          ...state,
          waitingForResponse: false,
        };
      }
      if (action.httpState === "error") {
        newState = {
          ...state,
          errorResponse: true,
        };
      }

      return [newState];
    }

    case "DispatchAdmin": {
      if (state.type !== "LoggedInState" || !state.adminState) {
        return [state];
      }
      const [adminState, adminCmd] = Admin.update(action.action, state.adminState, state.activeUser);
      return [{ ...state, adminState }, Cmd.batch<Action>([Cmd.map(Action.DispatchAdmin, adminCmd)])];
    }

    case "NoOp": {
      return [state];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
