import React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as Admin from "../admin";
import { Alert, Spinner } from "../../elements";
import { Header } from "./components/header";
import { ErrorBoundary } from "./error-boundary";

interface Props {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
}

export function view({ state, dispatch }: Props): JSX.Element {
  if (state.type === "ErrorState") {
    return <div>{`Error: ${state.reason}`}</div>;
  }

  if (state.type === "WaitingForUserSessionState") {
    return <div>LoginErrorState</div>;
  }
  if (state.type === "LoggedOutState") {
    return <div>LoggedOutState</div>;
  }

  if (!state.adminState) {
    return <Spinner />;
  }

  return (
    <div className="w-full text-xs prose-base prose prose-xs">
      <ErrorBoundary>
        <Header activeUser={state.activeUser} dispatch={dispatch} />
        <div className="relative m-2 mt-6">
          <div className="absolute top-2 right-2">
            {state.waitingForResponse ? <Spinner /> : undefined}
            {state.errorResponse ? (
              <Alert type="error">A HTTP request error occured. Try refreshing the page.</Alert>
            ) : undefined}
          </div>

          <Admin.View
            dispatch={Dispatch.map(State.Action.DispatchAdmin, dispatch)}
            state={state.adminState}
            activeUser={state.activeUser}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
}
