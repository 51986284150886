import * as React from "react";

export interface ToolbarProps {
  readonly align?: "left" | "right";
  readonly children?: React.ReactNode;
}

export function Toolbar(props: ToolbarProps): React.ReactElement<ToolbarProps> {
  const { children } = props;
  return (
    <div className={props.align === "right" ? "flex flex-row justify-end space-x-2" : "flex flex-row space-x-2"}>
      {children}
    </div>
  );
}
