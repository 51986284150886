import React, { useState } from "react";
import { Dispatch } from "@typescript-tea/core";
import { appInfo, User } from "@ka/shared";
import * as State from "../../state";
import { Button, Icon } from "../../../../elements";
import { clientConfig } from "../../../../client-config";

export function Header({
  dispatch,
  activeUser,
}: {
  readonly dispatch: Dispatch<State.Action>;
  readonly activeUser: User.ActiveUser;
}): JSX.Element {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="w-full flex flex-row space-x-10 justify-between border-b p-2">
      <img src={clientConfig.logo_image} className="max-h-16" />
      <div className="flex flex-row justify-end space-x-2 items-center max-h-6">
        <div>{activeUser.userName}</div>
        <Button type={"secondary"} label={"Logout"} onClick={() => dispatch(State.Action.Logout())} />
        <div className="flex flex-row" onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? (
            <div className="flex flex-col">
              <small>Tag: {appInfo.tag}</small>
              <small>PipelineId: {appInfo.pipelineId}</small>
            </div>
          ) : (
            <Icon icon="info-circle" colorClass="text-primary" />
          )}
        </div>
      </div>
    </div>
  );
}
