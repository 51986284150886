import * as React from "react";

export function Spinner({ disableDebounce = false }: { readonly disableDebounce?: boolean }): React.ReactElement<{}> {
  const [showSpinner, setShowSpinner] = React.useState(disableDebounce ? true : false);
  React.useEffect(() => {
    if (!disableDebounce) {
      const timer = setTimeout(() => {
        setShowSpinner(true);
      }, 50);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [disableDebounce]);
  return (
    <div className="inline-block w-6 h-6">
      {showSpinner ? (
        <svg className="animate-spin max-w-6 max-h-6" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : undefined}
    </div>
  );
}
