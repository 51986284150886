/* eslint-disable functional/no-this-expression */
import * as React from "react";
import { twClass, withTw } from "./with-tw";
import { Icon } from "./icon";
import { useDebounce } from "./debounce";
import { useClickOutside } from "./use-click-outside";
import { Checkbox } from "./checkbox";

interface Props {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly onKeyDown?: (keyCode: number) => void;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly autoFocus?: boolean;
  readonly placeholder?: string;
  readonly label?: string;
  readonly multiLine?: boolean;
  readonly debounce?: boolean;
  readonly debounceTime?: number;
  readonly disabled?: boolean;
  readonly alwaysUpdate?: boolean;
  readonly isRequiredMessage?: string;
  readonly errorMessage?: string;
  readonly className?: string;
  readonly classNameInner?: string;
  readonly options?: ReadonlyArray<string>;
}

const inputClass = twClass`${(props: Props) => (props.disabled ? "form-input-disabled" : "")} ${(props: Props) =>
  props.errorMessage ? "border-error" : ""}`;

const twClassNames = "w-full input input-xs input-bordered spin-button-none";

type InputWrapperProps = Pick<Props, "options" | "className">;
const InputWrapper = withTw(
  "div",
  (props: InputWrapperProps) => (props.options ? "join" : ""),
  (props: InputWrapperProps) => props.className || ""
);

const separator = ";";

export function Textfield(props: Props): JSX.Element {
  const {
    onKeyDown,
    placeholder,
    label,
    onFocus,
    onBlur,
    multiLine,
    debounce = true,
    disabled,
    className,
    classNameInner,
    options,
  } = props;

  const [value, setValue] = React.useState(props.value);
  const [hasFocus, setHasFocus] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const textInputRef = React.useRef<HTMLInputElement>(null);
  const textAreaInputRef = React.useRef<HTMLTextAreaElement>(null);

  const onChange = React.useCallback(
    (newValue: string): void => {
      if (props.disabled) {
        return;
      }
      if (props.value !== newValue) {
        props.onChange(newValue);
      }
    },
    [props.disabled, props.value, props.onChange]
  );

  const debouncedOnChange = useDebounce((newValue: string) => {
    props.onChange(newValue);
  }, 500);

  const onChangeLocal = React.useCallback(
    (newValue: string): void => {
      debouncedOnChange.set(newValue);
      setValue(newValue);
    },
    [setValue, debouncedOnChange.set]
  );

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    const element = textAreaInputRef.current || textInputRef.current;
    if (props.autoFocus && element) {
      element.focus();
      element.select();
    }
  }, [textInputRef.current]);

  React.useEffect(() => {
    if (props.alwaysUpdate || !debounce || !hasFocus) {
      setValue(props.value);
    }
  }, [props.value, props.alwaysUpdate, debounce, hasFocus]);

  const popupElement = React.useRef<HTMLDivElement>(null);
  useClickOutside(popupElement, () => setIsOpen(false));

  const input = multiLine ? (
    <textarea
      className={`${twClassNames} ${classNameInner}`}
      ref={textAreaInputRef}
      placeholder={placeholder}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        debounce ? onChangeLocal(e.currentTarget.value) : onChange(e.currentTarget.value)
      }
      onKeyDown={(e: React.KeyboardEvent) => onKeyDown && onKeyDown(e.keyCode)}
      onBlur={() => {
        setHasFocus(false);
        if (debounce) {
          debouncedOnChange.cancel();
        }
        if (value !== props.value) {
          props.onChange(value);
        }
        if (onBlur) {
          onBlur();
        }
      }}
      onFocus={() => {
        setHasFocus(true);
        if (onFocus) {
          onFocus();
        }
      }}
      rows={5}
      disabled={disabled}
    />
  ) : (
    <input
      className={`${inputClass(props)} ${twClassNames} ${classNameInner} join-item`}
      ref={textInputRef}
      placeholder={placeholder}
      value={value}
      onClick={() => setIsOpen(false)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        debounce ? onChangeLocal(e.currentTarget.value) : onChange(e.currentTarget.value)
      }
      onBlur={() => {
        setHasFocus(false);
        if (debounce) {
          debouncedOnChange.cancel();
        }
        if (value !== props.value) {
          props.onChange(value);
        }
        if (onBlur) {
          onBlur();
        }
      }}
      onFocus={() => {
        setHasFocus(true);
        if (onFocus) {
          onFocus();
        }
      }}
      disabled={disabled}
    />
  );
  const valueOptions = value
    .split(separator)
    .filter((o) => !!o)
    .map((o) => o.trim());
  if (label) {
    return (
      <div className={className}>
        <label className="block">{label}</label>
        {input}
      </div>
    );
  } else {
    return (
      <div className="relative" ref={popupElement}>
        <InputWrapper options={options} className={className}>
          {input}
          {options && (
            <button className="btn btn-xs join-item" onClick={() => setIsOpen(!isOpen)}>
              <Icon icon="chevron-down" size={"sm"} />
            </button>
          )}
        </InputWrapper>
        {isOpen && options && (
          <ul
            tabIndex={0}
            className="dropdown-content absolute z-[1] top-6 menu text-xs p-2 shadow bg-base-100 rounded-box w-52"
          >
            {options
              .map((o) => o.trim())
              .map((o) => (
                <li key={o} className="flex flex-row flex-nowrap items-center">
                  <Checkbox
                    checked={valueOptions.includes(o)}
                    onChange={(checked) => {
                      if (debounce) {
                        debouncedOnChange.cancel();
                      }
                      if (checked && !valueOptions.includes(o)) {
                        onChange([...valueOptions, o].join(separator));
                      } else if (!checked) {
                        onChange(valueOptions.filter((v) => v !== o).join(separator));
                      }
                    }}
                    label={o}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    );
  }
}
