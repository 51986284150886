import * as React from "react";
import { withTw } from ".";
import { useClickOutside } from "./use-click-outside";

interface LinkButtonProps {
  readonly active: boolean;
  readonly isOverflow: boolean;
}

const LinkButton = withTw(
  "div",
  ({ isOverflow }: LinkButtonProps) => (isOverflow ? "select-none cursor-pointer" : ""),
  ({ active, isOverflow }: LinkButtonProps) => (isOverflow ? (active ? "text-primary-light" : "text-primary") : "")
);

export interface TextPopupProps {
  readonly text: string;
}

export function TextPopup({ text }: TextPopupProps): React.ReactElement<TextPopupProps> {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOverflow, setIsOverflow] = React.useState(false);
  const textElement = React.useRef<HTMLDivElement>(null);
  const popupElement = React.useRef<HTMLDivElement>(null);
  useClickOutside(popupElement, () => setIsOpen(false));
  React.useEffect(() => {
    if (textElement.current) {
      const overflow = textElement.current.offsetWidth < textElement.current.scrollWidth;
      setIsOverflow(overflow);
      if (isOpen && !overflow) {
        setIsOpen(false);
      }
    }
  });
  return (
    <div className="relative inline-block">
      <div
        onClick={() => {
          if (!isOpen && isOverflow) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
      >
        <div className="">
          <LinkButton active={isOpen} isOverflow={isOverflow} onClick={() => undefined}>
            <div ref={textElement} className="whitespace-nowrap text-ellipsis overflow-hidden max-w-600">
              {text}
            </div>
            f
          </LinkButton>
        </div>
      </div>
      {isOpen ? (
        <div
          ref={popupElement}
          className="z-50 absolute right-0 flex flex-col drop-shadow-xl p-4 border rounded-sm border-gray-200 bg-white max-w-xs w-max"
        >
          {text}
        </div>
      ) : undefined}
    </div>
  );
}
