/* eslint-disable @typescript-eslint/no-explicit-any */
import { User } from "./types";

export type RoleRepresentation = {
  readonly clientRole?: boolean;
  readonly composite?: boolean;
  readonly containerId?: string;
  readonly description?: string;
  readonly id?: string;
  readonly name?: string;
};

export type RoleMappingRepresentation = {
  readonly id: string;
  readonly name: string;
  //readonly composite: boolean;
  //readonly clientRole: boolean;
  //readonly containerId: string;
};

export type UserSessionRepresentation = {
  readonly id: string;
  readonly ipAddress?: string;
  readonly lastAccess?: Date;
  readonly rememberMe?: boolean;
  readonly start?: Date;
  readonly userId: string;
  readonly username?: string;
  readonly clients?: { readonly [clientId: string]: string };
};

export type UserRepresentation = {
  readonly access?: {};
  readonly attributes?: { readonly [attributeName: string]: ReadonlyArray<string> };
  readonly clientConsents?: ReadonlyArray<any>;
  readonly clientRoles?: {};
  readonly createdTimestamp?: number;
  readonly credentials?: ReadonlyArray<any>;
  readonly disableableCredentialTypes?: ReadonlyArray<string>;
  readonly email?: string;
  readonly emailVerified?: boolean;
  readonly enabled?: boolean;
  readonly federatedIdentities?: ReadonlyArray<any>;
  readonly federationLink?: string;
  readonly firstName?: string;
  readonly groups?: ReadonlyArray<string>;
  readonly id?: string;
  readonly lastName?: string;
  readonly notBefore?: number;
  readonly origin?: string;
  readonly realmRoles?: ReadonlyArray<string>;
  readonly requiredActions?: ReadonlyArray<string>;
  readonly self?: string;
  readonly serviceAccountClientId?: string;
  readonly username?: string;
};

export type GetUsersResponse = ReadonlyArray<GetUserResponse>;
export type GetUserResponse = any;
export type GetUserSessionsResponse = ReadonlyArray<GetUserSessionResponse>;
export type GetUserSessionResponse = any;
export type GetRolesResponse = ReadonlyArray<RoleRepresentation>;
export type GetUserCountResponse = number;

export function handleGetRolesResponse(response: GetUsersResponse): ReadonlyArray<RoleRepresentation> {
  if (Array.isArray(response)) {
    return response;
  } else {
    return [];
  }
}

export function handleGetUserSessionsResponse(
  response: GetUserSessionResponse
): ReadonlyArray<UserSessionRepresentation> | undefined {
  if (!Array.isArray(response)) {
    return [];
  }
  return response
    .filter((r) => !!r.id)
    .map((r) => {
      return handleGetUserSessionResponse(r);
    });
}

export function handleGetUserSessionResponse(response: GetUserSessionResponse): UserSessionRepresentation {
  if (typeof response !== "object" || response.id === undefined) {
    // TODO: handle this better?
    throw new Error(`Got invalid user data response: ${JSON.stringify(response)}`);
  }
  return response;
}

export function handleGetUsersResponse(response: GetUsersResponse): ReadonlyArray<User> {
  if (!Array.isArray(response)) {
    return [];
  }
  return response
    .filter((r) => !!r.id)
    .map((r) => {
      const rep = handleGetUserResponse(r);
      return {
        ...rep,
        id: rep.id || "",
        enabled: rep.enabled || false,
        username: rep.username || "",
        email: rep.email || "",
        emailVerified: rep.emailVerified || false,
        firstName: rep.firstName || "",
        lastName: rep.lastName || "",
        attributes: rep.attributes || {},
        createdTimestamp: rep.createdTimestamp || 0,
      };
    });
}

export function handleGetUserResponse(response: GetUserResponse): UserRepresentation {
  if (typeof response !== "object" || response.id === undefined) {
    // TODO: handle this better?
    throw new Error(`Got invalid user data response: ${JSON.stringify(response)}`);
  }
  return {
    ...response,
    attributes: response.attributes || {},
  };
}

export function handleGetUserCountResponse(response: GetUserCountResponse): number | undefined {
  if (typeof response === "number") {
    return response;
  } else {
    return undefined;
  }
}
