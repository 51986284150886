import { Keycloak } from "@ka/shared";

// Hard coded for simplicity
export const locales: ReadonlyArray<string> = [
  "ar-AE",
  "az-AZ",
  "be-BY",
  "bg-BG",
  "cz-CZ",
  "da-DK",
  "de-AT",
  "de-CH",
  "de-DE",
  "el-GR",
  "en-AE",
  "en-CA",
  "en-GB",
  "en-IE",
  "en-KE",
  "en-QA",
  "en-US",
  "en-ZA",
  "es-CL",
  "es-ES",
  "es-MX",
  "es-PE",
  "et-EE",
  "fi-FI",
  "fr-BE",
  "fr-CH",
  "fr-FR",
  "fr-MA",
  "hr-HR",
  "hu-HU",
  "it-CH",
  "it-IT",
  "ka-GE",
  "lt-LT",
  "lv-LV",
  "nl-BE",
  "nl-NL",
  "no-NO",
  "pl-PL",
  "pt-PT",
  "ro-RO",
  "ru-KZ",
  "ru-RU",
  "sk-SK",
  "sl-SI",
  "sr-RS",
  "sv-SE",
  "tr-TR",
  "uk-UA",
];

export const userFields: ReadonlyArray<readonly [keyof Keycloak.UserPartial, "text" | "bool" | "date", string]> = [
  ["username", "text", "User"],
  ["firstName", "text", "First name"],
  ["lastName", "text", "Last name"],
  ["emailVerified", "text", ""],
  ["email", "text", "Email"],
  ["createdTimestamp", "date", "Created"],
];

export type UserMetaField = "view_users" | "manage_users" | "enabled";

export const userMetaFields: ReadonlyArray<readonly [UserMetaField, string]> = [
  ["view_users", "View users"],
  ["manage_users", "Manage users"],
  ["enabled", "Enabled"],
];
