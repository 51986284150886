import React from "react";

type DebounceValueType = string | number | boolean | undefined;

export function useDebounce(
  func: (arg: DebounceValueType) => void,
  delay: number = 500
): { readonly cancel: () => void; readonly set: (value: DebounceValueType) => void } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timer = React.useRef<number | undefined>(undefined);
  const [value, startDebounce] = React.useState<DebounceValueType>(undefined);
  const resetTimer = React.useCallback(() => {
    timer.current && clearTimeout(timer.current);
  }, [timer]);

  React.useEffect((): (() => void) => {
    if (value) {
      timer.current = setTimeout(() => func(value), delay);
    }
    return resetTimer;
  }, [value, delay]);

  return {
    cancel: resetTimer,
    set: startDebounce,
  };
}
