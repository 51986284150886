export interface ClientConfig {
  readonly server_path_prefix: string;
  readonly env: string;
  readonly dsn: string | undefined;
  readonly oidc_login_url: string;
  readonly oidc_client_id: string;
  readonly oidc_post_logout_url?: string;
  readonly keycloak_realm: string;
  readonly keycloak_client_application_id: string;
  readonly keycloak_client_id_of_client_realm_management_application: string;
  readonly keykloak_role_id_manage_users: string;
  readonly keykloak_role_id_view_users: string;
  readonly keykloak_role_id_view_clients: string;
  readonly class_promaster_marker: string;
  readonly class_eso_details_product_id: string;
  readonly logo_image: string;
  readonly custom_search_attributes: string;
}
