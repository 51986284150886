import { exhaustiveCheck } from "ts-exhaustive-check";
import { Keycloak, User, isDefined } from "@ka/shared";
import { Dispatch } from "@typescript-tea/core";
import * as React from "react";
import * as State from ".";
import { customerNumberApprovedAttributeName, customerNumberAttributeName } from ".";
import { Checkbox, Icon, Selector, Spinner, Textfield, TextPopup } from "../../elements";
import { Popup } from "../../elements/popup";
import { Dropdown } from "../../elements/dropdown";
import { UserMetaField, locales, userFields, userMetaFields } from "./user-tables-shared";

export function UserTable({
  users,
  userRoles,
  userSessions,
  attributeConfig,
  dispatch,
  readOnly,
  activeUser,
  usedApplicationsByUserId,
}: {
  readonly users: ReadonlyArray<Keycloak.User>;
  readonly userRoles: State.UserRoles;
  readonly userSessions: ReadonlyMap<string, Keycloak.UserSessionRepresentation | undefined>;
  readonly attributeConfig: Keycloak.AttributeConfiguration;
  readonly dispatch: Dispatch<State.Action>;
  readonly readOnly: boolean;
  readonly activeUser: User.ActiveUser;
  readonly usedApplicationsByUserId: State.UsedApplicationsByUserId;
}): JSX.Element {
  const activeUserEmail = activeUser.email.toLowerCase();
  return (
    <table>
      <thead>
        <tr>
          {userFields.map(([field, _type, name]) => (
            <th className="text-left" key={field}>
              {name}
            </th>
          ))}
          {attributeConfig.map((ac) => (
            <th className="text-left" key={ac.name}>
              {ac.displayName}
            </th>
          ))}
          <th className="text-left">{"Used applications"}</th>
          {userMetaFields.map(([field, name]) => (
            <th key={field} className="text-left">
              {name}
            </th>
          ))}
          <th className="text-left">{""}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          const attributeValues = Keycloak.attributeValuesFromUser(attributeConfig, user);
          const hasCustomerNumber = attributeValues.some(
            (a) => a.attribute.name === customerNumberAttributeName && a.type === "text" && !a.multi && a.value
          );
          return (
            <tr key={user.id} className="hover:bg-gray-50">
              {userFields.map(([field, type]) => (
                <td className="break-words" key={field}>
                  {renderField(type, field, user[field])}
                </td>
              ))}
              {attributeValues.map((v) => (
                <td key={v.attribute.name}>
                  <AttributeValue
                    value={v}
                    onChange={(newValue) =>
                      !readOnly ? dispatch(State.Action.UpdateAttribute(user.id, newValue)) : undefined
                    }
                    readOnly={
                      readOnly || (v.attribute.name === customerNumberApprovedAttributeName && !hasCustomerNumber)
                    }
                  />
                </td>
              ))}
              <td>
                <Popup
                  label="View"
                  Content={UsedApplications}
                  contentProps={{ user: user, usedApplications: usedApplicationsByUserId[user.id], dispatch: dispatch }}
                />
              </td>
              {userMetaFields.map(([field, _name]) => (
                <td key={field}>
                  <UserMetaFieldColumn
                    readOnly={readOnly}
                    activeUserEmail={activeUserEmail}
                    userRoles={userRoles}
                    user={user}
                    dispatch={dispatch}
                    field={field}
                  />
                </td>
              ))}
              <td>
                <Dropdown
                  anchorPoint={"right"}
                  onClick={() => !userSessions.has(user.id) && dispatch(State.Action.GetUserSession(user.id))}
                  actions={[
                    ...(!user.emailVerified
                      ? [
                          {
                            label: "Resend email-verification",
                            onClick: () => dispatch(State.Action.SendEmailVerification(user.id)),
                          },
                          {
                            label: "Verify email",
                            onClick: () => dispatch(State.Action.UpdateUser(user.id, { emailVerified: true })),
                          },
                        ]
                      : []),
                    userSessions.has(user.id)
                      ? userSessions.get(user.id) === undefined
                        ? undefined
                        : {
                            label: "Logout",
                            onClick: () => dispatch(State.Action.SendLogoutUser(user.id)),
                          }
                      : {
                          label: <Spinner />,
                          onClick: () => null,
                        },
                  ].filter(isDefined)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function UserMetaFieldColumn({
  readOnly,
  activeUserEmail,
  userRoles,
  user,
  dispatch,
  field,
}: {
  readonly readOnly: boolean;
  readonly activeUserEmail: string;
  readonly userRoles: State.UserRoles;
  readonly user: Keycloak.User;
  readonly dispatch: Dispatch<State.Action>;
  readonly field: UserMetaField;
}): JSX.Element {
  switch (field) {
    case "view_users":
      return (
        <Checkbox
          disabled={readOnly || user.email.toLowerCase() === activeUserEmail}
          checked={State.hasRole(user.id, "view-users", userRoles)}
          onChange={(checked) => dispatch(State.Action.UpdateRole(user.id, "view-users", checked))}
        />
      );
    case "manage_users":
      return (
        <Checkbox
          disabled={readOnly || user.email.toLowerCase() === activeUserEmail}
          checked={State.hasRole(user.id, "manage-users", userRoles)}
          onChange={(checked) => dispatch(State.Action.UpdateRole(user.id, "manage-users", checked))}
        />
      );
    case "enabled":
      return (
        <Checkbox
          disabled={readOnly || user.email.toLowerCase() === activeUserEmail}
          checked={user.enabled}
          onChange={(checked) => dispatch(State.Action.UpdateUser(user.id, { enabled: checked }))}
        />
      );
    default:
      return exhaustiveCheck(field, true);
  }
}

function UsedApplications({
  user,
  usedApplications,
  dispatch,
}: {
  readonly user: Keycloak.User;
  readonly usedApplications: ReadonlyArray<string> | "fetching" | undefined;
  readonly dispatch: Dispatch<State.Action>;
}): JSX.Element {
  React.useEffect(() => {
    if (!usedApplications) {
      dispatch(State.Action.GetUsedApplications(user));
    }
  }, [usedApplications]);
  return (
    <div className="flex flex-row items-center w-max space-x-4 min-h-24">
      <span>{"Used applications:"}</span>
      {!usedApplications || usedApplications === "fetching" ? (
        <span style={{ display: "grid", height: "15px", width: "15px" }}>
          <Spinner disableDebounce={true} />
        </span>
      ) : usedApplications.length === 0 ? (
        <span className="italic">None</span>
      ) : (
        <span>{usedApplications.join(", ")}</span>
      )}
    </div>
  );
}

function renderField(
  type: "text" | "bool" | "date",
  field: keyof Keycloak.UserPartial,
  value: unknown
): JSX.Element | string {
  if (field === "emailVerified") {
    return value === true ? (
      ""
    ) : (
      <Icon
        icon={"exclamation-triangle"}
        colorClass="text-warning"
        className="text-xs"
        message="Email is not approved"
      />
    );
  } else if (type === "text" && typeof value === "string") {
    return value;
  } else if (type === "bool") {
    return value ? "Yes" : "No";
  } else if (type === "date" && typeof value === "number") {
    return new Date(value).toLocaleString();
  } else {
    return "-";
  }
}

function AttributeValue({
  value,
  onChange,
  readOnly,
}: {
  readonly value: Keycloak.AttributeValue;
  readonly onChange: (newValue: Keycloak.AttributeValue) => void;
  readonly readOnly: boolean;
}): JSX.Element {
  switch (value.attribute.customUi) {
    case "custom_selector_test":
      return <span>Test</span>;
    default:
      return <AttributeValueStandard value={value} onChange={onChange} readOnly={readOnly} />;
  }
}

function AttributeValueStandard({
  value,
  onChange,
  readOnly,
}: {
  readonly value: Keycloak.AttributeValue;
  readonly onChange: (newValue: Keycloak.AttributeValue) => void;
  readonly readOnly: boolean;
}): JSX.Element {
  const disabled = readOnly || value.attribute.readOnly;
  switch (value.type) {
    case "text": {
      const textValue = value.multi ? value.values.join(";") : value.value;
      return disabled ? (
        <TextPopup text={textValue} />
      ) : (
        <Textfield
          className="max-w-xs"
          disabled={disabled}
          value={textValue}
          onChange={(v) =>
            onChange(
              Keycloak.createTextValue(value.attribute, value.attribute.multi ? v.split(";").map((v) => v.trim()) : v)
            )
          }
          options={value.attribute.options}
        />
      );
    }
    case "bool":
      return (
        <Checkbox
          disabled={disabled}
          checked={value.value}
          onChange={() => onChange(Keycloak.createBoolValue(value.attribute, !value.value))}
        />
      );
    case "discrete": {
      const options = (
        value.attribute.options.some((o) => o === value.value)
          ? value.attribute.options
          : [value.value || "", ...value.attribute.options]
      ).map((o) => ({ id: o, name: o }));
      return (
        <Selector
          disabled={disabled}
          value={options.find((o) => o.id === value.value) || options[0]}
          options={options}
          onChange={(v) => onChange(Keycloak.createDiscreteValue(value.attribute, v.id))}
        />
      );
    }
    case "locale": {
      const options = (locales.some((o) => o === value.locale) ? locales : [value.locale || "", ...locales]).map(
        (o) => ({ id: o, name: o })
      );
      return (
        <Selector
          disabled={disabled}
          value={options.find((o) => o.id === value.locale) || options[0]}
          options={options}
          onChange={(v) => onChange(Keycloak.createLocaleValue(value.attribute, v.id))}
        />
      );
    }
    default:
      return <span />;
  }
}
