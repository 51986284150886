import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { User } from "@ka/shared";
import * as State from "./state";
import { UserTable } from "./user-table";
import { Alert, Button, H2, SelectOption, Selector, Spinner, Textfield } from "../../elements";
import { clientConfig } from "../../client-config";

export function View({
  state,
  dispatch,
  activeUser,
}: {
  readonly state: State.State;
  readonly dispatch: Dispatch<State.Action>;
  readonly activeUser: User.ActiveUser;
}): JSX.Element {
  if (state.canManageUsers === undefined || state.canViewUsers === undefined) {
    return <span />;
  }

  const pageTitle = `User Administration (${clientConfig.keycloak_realm})`;

  if (!state.canManageUsers && !state.canViewUsers) {
    return (
      <div>
        <h1 className="capitalize">{pageTitle}</h1>
        <Alert type="error">
          <H2>User Unauthorized</H2>
          The current user account does not have sufficient authorization for user administration
        </Alert>
      </div>
    );
  }

  if (state.users === undefined) {
    return <span />;
  }

  const searchParamToId = (sp: State.SearchParam): string =>
    sp.type === "builtin" ? `builtin:${sp.param}` : `attribute:${sp.attribute}`;
  const searchParamOptions = (
    [
      [{ type: "builtin", param: "username" }, "User name"],
      [{ type: "builtin", param: "email" }, "Email"],
      [{ type: "builtin", param: "firstname" }, "First name"],
      [{ type: "builtin", param: "lastname" }, "Last name"],
      ...getCustomAttributeParams(clientConfig.custom_search_attributes),
    ] as Array<[State.SearchParam, string]>
  ).map(([param, name]) => ({
    id: searchParamToId(param),
    name: name,
    param: param,
  }));

  const filterOptions: ReadonlyArray<SelectOption<{ readonly filter: State.Filter }>> = [
    { id: "all", filter: "all", name: "All" },
    // { id: "approved-users", filter: "approved-users", name: "Approved customer numbers" },
    // { id: "waiting-for-approval", filter: "waiting-for-approval", name: "Waiting for customer number approval" },
    // { id: "declined-users", filter: "declined-users", name: "Declined customer number" },
  ];

  return (
    <div className="flex flex-col space-y-6">
      <h1 className="capitalize">{pageTitle}</h1>
      <div className="flex flex-row justify-between w-full mb-8">
        <div className="flex flex-row space-x-6 items-center">
          <div className="flex flex-row space-x-2 items-center">
            <Textfield
              placeholder="Enter search text"
              className="max-w-64"
              onChange={(v) => dispatch(State.Action.ChangeSearchQuery(v))}
              value={state.searchQuery}
            />
            <Selector
              value={
                searchParamOptions.find((o) => o.id === searchParamToId(state.searchParam)) || searchParamOptions[0]
              }
              options={searchParamOptions}
              onChange={(v) => dispatch(State.Action.SetSearchParam(v.param))}
            />
          </div>
          {filterOptions.length > 1 && (
            <div className="flex flex-row space-x-2 items-center">
              <span>{"Filter users:"}</span>
              <Selector
                value={filterOptions.find((o) => o.filter === state.filter) || filterOptions[0]}
                options={filterOptions}
                onChange={(o) => dispatch(State.Action.SetFilter(o.filter))}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row space-x-2">
          {state.canManageUsers && (
            <Button
              label="Download all users (XLSX)"
              onClick={() => dispatch(State.Action.DownloadUserListInitiate())}
              disabled={!!state.downloadedUsersSofar}
            />
          )}
        </div>
      </div>
      <UserTable
        users={state.users}
        userRoles={state.userRoles}
        userSessions={state.userSessions}
        attributeConfig={state.attributeConfig}
        dispatch={dispatch}
        readOnly={!state.canManageUsers}
        activeUser={activeUser}
        usedApplicationsByUserId={state.usedApplicationByUserId}
      />
      {state.noUsersFound && <p className="text-sm text-center p-4">{"No users found"}</p>}
      <GetMoreUsers
        numUsers={state.users.length}
        desiredUserCount={state.desiredUserCount}
        hasMoreUsers={state.hasMoreUsers}
        onGetMoreUsers={() => dispatch(State.Action.GetMoreUsers())}
      />
    </div>
  );
}

function GetMoreUsers({
  numUsers,
  desiredUserCount,
  hasMoreUsers,
  onGetMoreUsers,
}: {
  readonly numUsers: number;
  readonly desiredUserCount: number;
  readonly hasMoreUsers: boolean;
  readonly onGetMoreUsers: () => void;
}): JSX.Element {
  if (!hasMoreUsers) {
    return <span />;
  }
  return (
    <div className="flex flex-col justify-center pb-64 space-y-16">
      <div className="self-center">
        {numUsers < desiredUserCount ? (
          <div className="flex space-x-16 text-sm items-center">
            <Spinner disableDebounce={true} />
            <span>{"Retreiving users..."}</span>{" "}
          </div>
        ) : (
          <Button className="text-sm" label="Get more users" onClick={() => onGetMoreUsers()} />
        )}
      </div>
    </div>
  );
}

function getCustomAttributeParams(
  customSearchAttributes: string
): ReadonlyArray<readonly [param: State.SearchParam, displayName: string]> {
  const params: Array<[State.SearchParam, string]> = [];
  for (const [attributeName, displayName] of customSearchAttributes.split(";").map((p) => p.split(","))) {
    if (!attributeName || !displayName) {
      continue;
    }
    params.push([{ type: "attribute", attribute: attributeName }, displayName]);
  }
  return params;
}
