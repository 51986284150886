import * as XLSX from "xlsx";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Keycloak } from "@ka/shared";
import * as State from ".";
import { UserMetaField, locales, userFields, userMetaFields } from "./user-tables-shared";

export interface XlsxFile {
  readonly data: Blob;
  readonly fileName: string;
}

export function createXlsxFile(
  attributeConfig: Keycloak.AttributeConfiguration,
  userRoles: State.UserRoles,
  users: ReadonlyArray<Keycloak.User>
): XlsxFile {
  const rows = createRows(attributeConfig, userRoles, users);
  const wb: XLSX.WorkBook = {
    SheetNames: ["sheet"],
    Sheets: {
      sheet: XLSX.utils.aoa_to_sheet(rows),
    },
  };
  const buffer: File = XLSX.write(wb, { bookType: "xlsx", bookSST: false, type: "buffer" });
  const data = new Blob([buffer], {
    type: "application/vnd.ms-excel",
  });
  const fileName = `users_${new Date().toDateString()}`.replace(/[:|.|\s]/g, "_").toLowerCase() + ".xlsx";
  return {
    data,
    fileName,
  };
}

function createRows(
  attributeConfig: Keycloak.AttributeConfiguration,
  userRoles: State.UserRoles,
  users: ReadonlyArray<Keycloak.User>
  // eslint-disable-next-line functional/prefer-readonly-type
): Array<Array<string>> {
  const rows = [];

  rows.push([
    ...userFields.map(([_field, _type, name]) => name),
    ...attributeConfig.map((ac) => ac.displayName),
    ...userMetaFields.map(([_field, name]) => name),
  ]);

  for (const user of users) {
    const attributeValues = Keycloak.attributeValuesFromUser(attributeConfig, user);
    rows.push([
      ...userFields.map(([field, type]) => renderField(type, field, user[field])),
      ...attributeValues.map((v) => renderAttributeValue(v)),
      ...userMetaFields.map(([field, _name]) => renderMetaField(user, userRoles, field)),
    ]);
  }

  return rows;
}

function renderMetaField(user: Keycloak.UserPartial, userRoles: State.UserRoles, field: UserMetaField): string {
  switch (field) {
    case "view_users":
      return State.hasRole(user.id, "view-users", userRoles) ? "1" : "0";
    case "manage_users":
      return State.hasRole(user.id, "manage-users", userRoles) ? "1" : "0";
    case "enabled":
      return user.enabled ? "1" : "0";
    default:
      return exhaustiveCheck(field, true);
  }
}

function renderField(type: "text" | "bool" | "date", field: keyof Keycloak.UserPartial, value: unknown): string {
  if (field === "emailVerified") {
    return value === true ? "" : "no";
  } else if (type === "text" && typeof value === "string") {
    return value;
  } else if (type === "bool") {
    return value ? "1" : "0";
  } else if (type === "date" && typeof value === "number") {
    return new Date(value).toLocaleString();
  } else {
    return "-";
  }
}

function renderAttributeValue(value: Keycloak.AttributeValue): string {
  switch (value.attribute.customUi) {
    case "custom_selector_test":
      return "";
    default:
      switch (value.type) {
        case "text":
          return value.multi ? value.values.join(";") : value.value;
        case "bool":
          return value.value ? "1" : "0";
        case "discrete": {
          const options = (
            value.attribute.options.some((o) => o === value.value)
              ? value.attribute.options
              : [value.value || "", ...value.attribute.options]
          ).map((o) => ({ id: o, name: o }));
          const option = options.find((o) => o.id === value.value) || options[0];
          return option?.name;
        }
        case "locale": {
          const options = (locales.some((o) => o === value.locale) ? locales : [value.locale || "", ...locales]).map(
            (o) => ({ id: o, name: o })
          );
          const option = options.find((o) => o.id === value.locale) || options[0];
          return option.name;
        }
        default:
          return "";
      }
  }
}
