import { IconName } from "@fortawesome/fontawesome-svg-core";
import * as React from "react";
import { Icon, twClass } from ".";

export interface LinkButtonProps {
  readonly label: string;
  readonly onClick: () => void;
  readonly disabled?: boolean;
  readonly active?: boolean;
  readonly extraPadding?: boolean;
  readonly icon?: IconName;
  readonly iconRight?: boolean;
}

const linkClass = twClass`${(props: LinkButtonProps) =>
  !props.disabled && !props.active ? "cursor-pointer" : ""} select-none ${(props: LinkButtonProps) =>
  props.disabled ? "text-gray-600" : ""}  ${(props: LinkButtonProps) =>
  props.active ? "bg-secondary-light font-bold" : ""} ${(props: LinkButtonProps) =>
  props.extraPadding ? "px-1" : ""} flex flex-row space-x-1 items-center`;

// TODO: debounce

export function LinkButton(props: LinkButtonProps): React.ReactElement<LinkButtonProps> {
  const { onClick, label, disabled, ...htmlProps } = props;
  const icon = props.icon ? <Icon size={"xs"} icon={props.icon} disabled={disabled} /> : undefined;
  return disabled ? (
    <span className={linkClass(props)} {...htmlProps}>
      {!props.iconRight ? icon : undefined}
      <div>{label}</div>
      {props.iconRight ? icon : undefined}
    </span>
  ) : (
    <a className={linkClass(props)} onClick={() => onClick()} {...htmlProps}>
      {!props.iconRight ? icon : undefined}
      <div>{label}</div>
      {props.iconRight ? icon : undefined}
    </a>
  );
}
