import { exhaustiveCheck } from "ts-exhaustive-check";
import { User } from "./types";

export type AttributeCustomUi = "custom_selector_test";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAttributeCustomUI(customUi: any): customUi is AttributeCustomUi {
  return typeof customUi === "string" && ["custom_selector_test"].some((c) => c === customUi);
}

export type AttributeBase = {
  readonly name: string;
  readonly displayName: string;
  readonly readOnly: boolean;
  readonly customUi?: AttributeCustomUi;
};
export type TextAttribute = AttributeBase & {
  readonly type: "text";
  readonly multi: boolean;
  readonly options?: ReadonlyArray<string>;
};
export type BoolAttribute = AttributeBase & {
  readonly type: "bool";
  readonly alternativeValues?: AlternativeBoolValues;
};
export type AlternativeBoolValues = { readonly true: string; readonly false: string };
export type DiscreteAttribute = AttributeBase & { readonly type: "discrete"; readonly options: ReadonlyArray<string> };
export type LocaleAttribute = AttributeBase & {
  readonly type: "locale";
  readonly countryAttributeName: string;
  readonly languageAttributeName: string;
};
export type Attribute = TextAttribute | BoolAttribute | DiscreteAttribute | LocaleAttribute;

export type AttributeConfiguration = ReadonlyArray<Attribute>;

export type TextValue = { readonly type: "text"; readonly attribute: TextAttribute } & (
  | { readonly multi: false; readonly value: string }
  | { readonly multi: true; readonly values: ReadonlyArray<string> }
);
export type BoolValue = { readonly type: "bool"; readonly attribute: BoolAttribute; readonly value: boolean };
export type DiscreteValue = {
  readonly type: "discrete";
  readonly attribute: DiscreteAttribute;
  readonly value: string | undefined;
};
export type LocaleValue = {
  readonly type: "locale";
  readonly attribute: LocaleAttribute;
  readonly locale: string | undefined;
};

export type AttributeValue = TextValue | BoolValue | DiscreteValue | LocaleValue;

export function attributeValuesFromUser(
  attributeConfiguration: AttributeConfiguration,
  user: User
): ReadonlyArray<AttributeValue> {
  return attributeConfiguration.map((attribute) => {
    const value = (user.attributes[attribute.name] || [])[0] || "";
    switch (attribute.type) {
      case "text":
        return createTextValue(
          attribute,
          attribute.multi ? getMultiValueFromClaim(user, attribute.name) : getValueFromClaim(user, attribute.name)
        );
      case "bool":
        return createBoolValue(
          attribute,
          getValueFromClaim(user, attribute.name).toLocaleLowerCase() === "true"
            ? true
            : attribute.alternativeValues &&
              getValueFromClaim(user, attribute.name).toLocaleLowerCase() ===
                attribute.alternativeValues.true.toLocaleLowerCase()
            ? true
            : false
        );
      case "discrete":
        return createDiscreteValue(attribute, getValueFromClaim(user, attribute.name));
      case "locale": {
        const language = getValueFromClaim(user, attribute.languageAttributeName);
        const country = getValueFromClaim(user, attribute.countryAttributeName);
        if (language && country) {
          return createLocaleValue(attribute, `${language}-${country}`);
        } else {
          return createLocaleValue(attribute, "en-GB");
        }
      }
      default:
        return createTextValue(attribute, value);
    }
  });
}

export function getApiValue(value: AttributeValue): { readonly [attribte: string]: ReadonlyArray<string> | undefined } {
  switch (value.type) {
    case "text":
      return { [value.attribute.name]: value.multi ? value.values : value.value ? [value.value] : undefined };
    case "bool":
      if (value.attribute.alternativeValues) {
        return {
          [value.attribute.name]: value.value
            ? [value.value ? value.attribute.alternativeValues.true : value.attribute.alternativeValues.false]
            : undefined,
        };
      }
      return { [value.attribute.name]: value.value ? [value.value ? "true" : "false"] : undefined };
    case "discrete":
      return { [value.attribute.name]: value.value ? [value.value] : undefined };
    case "locale": {
      const localeParts = value.locale?.split("-") || [];
      const language = localeParts[0];
      const country = localeParts[1];
      if (language && country) {
        return {
          [value.attribute.languageAttributeName]: [language],
          [value.attribute.countryAttributeName]: [country],
        };
      } else {
        return {
          [value.attribute.languageAttributeName]: undefined,
          [value.attribute.countryAttributeName]: undefined,
        };
      }
    }
    default:
      return exhaustiveCheck(value, true);
  }
}

function getValueFromClaim(user: User, claim: string): string {
  return (user.attributes[claim] || [])[0] || "";
}

function getMultiValueFromClaim(user: User, claim: string): ReadonlyArray<string> {
  return user.attributes[claim] || [];
}

export function createTextValue(attribute: TextAttribute, value: string | ReadonlyArray<string>): AttributeValue {
  const values = typeof value === "string" ? [value] : value;
  return {
    type: "text",
    attribute,
    ...(attribute.multi ? { multi: true, values } : { multi: false, value: values[0] }),
  };
}

export function createBoolValue(attribute: BoolAttribute, value: boolean): AttributeValue {
  return { type: "bool", attribute, value };
}

export function createDiscreteValue(attribute: DiscreteAttribute, value: string): AttributeValue {
  return { type: "discrete", attribute, value: attribute.options.find((o) => o === value) };
}

export function createLocaleValue(attribute: LocaleAttribute, value: string): AttributeValue {
  return { type: "locale", attribute, locale: value };
}
