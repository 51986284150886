import * as React from "react";
import { Alert } from "./alert";
import { Button } from "./button";
import { Icon } from "./icon";
import { Toolbar } from "./toolbar";

export function ConfirmRemoveButton({
  disabled,
  onClick,
  confirmMessage,
  removeText,
  cancelText,
  regularButton,
  oneLiner,
}: {
  readonly disabled?: boolean;
  readonly onClick: () => void;
  readonly confirmMessage: string;
  readonly removeText: string;
  readonly cancelText: string;
  readonly regularButton?: boolean;
  readonly oneLiner?: boolean;
}): JSX.Element {
  const [confirmRemove, setConfirmRemove] = React.useState(false);
  return confirmRemove ? (
    <Alert key="alert" type="danger">
      {!oneLiner ? <span>{confirmMessage}</span> : <></>}
      <Toolbar align="right">
        {oneLiner ? <span>{confirmMessage}</span> : <></>}
        <Button type="danger" label={removeText} onClick={() => onClick()} />
        <Button type="secondary" label={cancelText} onClick={() => setConfirmRemove(false)} />
      </Toolbar>
    </Alert>
  ) : regularButton ? (
    <Button type="danger" label={removeText} disabled={disabled} onClick={() => setConfirmRemove(true)} />
  ) : (
    <Icon key="remove-button" icon={"trash-alt"} disabled={disabled} onClick={() => setConfirmRemove(true)} />
  );
}
