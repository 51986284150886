import { exhaustiveCheck } from "ts-exhaustive-check";
import { saveAs } from "file-saver";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import { Keycloak, User } from "@ka/shared";
import { HttpEffectManager, TimerEffectManager } from "../../effect-managers";
import { clientConfig } from "../../client-config";
import { createXlsxFile } from "./user-table-xlsx";

export const customerNumberAttributeName = "sys_sso_customer_number";
export const customerNumberApprovedAttributeName = "sys_sso_customer_number_approved";
export const customerNumberNoteAttributeName = "customer_number_note";

export type UserRoles = { readonly [roleName: string]: ReadonlySet<string> };

export type SearchParam =
  | { readonly type: "builtin"; readonly param: "username" | "email" | "firstname" | "lastname" }
  | { readonly type: "attribute"; readonly attribute: string };
export type Filter = "all" | "approved-users" | "waiting-for-approval" | "declined-users";

export type UsedApplicationsByUserId = Record<string, ReadonlyArray<string> | "fetching">;

export type State = {
  readonly attributeConfig: Keycloak.AttributeConfiguration;

  readonly users: ReadonlyArray<Keycloak.User> | undefined;
  readonly userRoles: UserRoles;
  readonly userSessions: ReadonlyMap<string, Keycloak.UserSessionRepresentation | undefined>;

  readonly canManageUsers: boolean | undefined;
  readonly canViewUsers: boolean | undefined;

  readonly searchQuery: string;
  readonly searchParam: SearchParam;
  readonly filter: Filter;
  readonly availableFilters: ReadonlyArray<Filter>;

  readonly nextSearchStart: number;
  readonly hasMoreUsers: boolean;
  readonly desiredUserCount: number;
  readonly noUsersFound: boolean;

  readonly usedApplicationByUserId: UsedApplicationsByUserId;

  readonly downloadedUsersSofar: ReadonlyArray<Keycloak.User> | undefined;
  readonly downloadedUsersCurrentPage: number;
};

const usersRefreshTimerId = "users-refresh";
const usersRefreshInterval = 1000 * 60 * 30; // 30 minutes
const usersPerPage = 50;

export const Action = ctorsUnion({
  ReceivedLoggedInUsersRoles: (response: Keycloak.GetRolesResponse | undefined) => ({ response }),
  ReceivedAttrbuteConfig: (attributeConfig: Keycloak.AttributeConfiguration) => ({ attributeConfig }),
  ReceivedUsers: (response: Keycloak.GetUsersResponse, hasMoreUsers: boolean, nextSearchStart: number) => ({
    response,
    hasMoreUsers,
    nextSearchStart,
  }),
  ReceivedUsersRole: (roleName: string, response: Keycloak.GetUsersResponse) => ({ roleName, response }),
  RefreshUserList: () => ({}),
  ChangeSearchQuery: (searchQuery: string) => ({ searchQuery }),
  GetUserSession: (userId: string) => ({ userId }),
  ReceivedUserSesssions: (response: Keycloak.GetUserSessionResponse, userId: string) => ({ response, userId }),
  GetMoreUsers: () => ({}),
  UpdateAttribute: (userId: string, value: Keycloak.AttributeValue) => ({ userId, value }),
  UpdateUser: (userId: string, value: Partial<Keycloak.User>) => ({ userId, value }),
  SendUpdatedAttributes: (remoteUser: Keycloak.UserRepresentation, updatedAttributes: Keycloak.User["attributes"]) => ({
    remoteUser,
    updatedAttributes,
  }),
  SendUpdatedUser: (remoteUser: Keycloak.UserRepresentation, updatedUser: Keycloak.User) => ({
    remoteUser,
    updatedUser,
  }),
  SendEmailVerification: (userId: string) => ({
    userId,
  }),
  SendLogoutUser: (userId: string) => ({
    userId,
  }),
  RemoveUserSession: (userId: string) => ({
    userId,
  }),
  UpdateRole: (userId: string, role: "view-users" | "manage-users", hasRole: boolean) => ({ userId, role, hasRole }),
  SetSearchParam: (searchParam: SearchParam) => ({ searchParam }),
  SetFilter: (filter: Filter) => ({ filter }),
  GetUsedApplications: (user: Keycloak.User) => ({ user }),
  ReceivedUsedApplications: (user: Keycloak.User, usedApplications: ReadonlyArray<string>) => ({
    user,
    usedApplications,
  }),
  DownloadUserListInitiate: () => ({}),
  DownloadUserListReceivedUsers: (response: Keycloak.GetUsersResponse) => ({ response }),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  _urlPrefix: string,
  activeUser: User.ActiveUser,
  _prevState: State | undefined
): readonly [State, Cmd<Action>?] {
  const rolesCmd = HttpEffectManager.fetchOne(
    buildAuthHeaders(activeUser),
    buildUrl(
      `/keycloak/users/${activeUser.externalId}/role-mappings/clients/${clientConfig.keycloak_client_id_of_client_realm_management_application}`
    ),
    "json",
    (data: Keycloak.GetRolesResponse) => Action.ReceivedLoggedInUsersRoles(data),
    () => Action.ReceivedLoggedInUsersRoles(undefined)
  );
  return [
    {
      attributeConfig: [],
      users: undefined,
      userRoles: {},
      userSessions: new Map(),

      canManageUsers: undefined,
      canViewUsers: undefined,

      searchQuery: "",
      searchParam: { type: "builtin", param: "email" },
      filter: "all",
      availableFilters: getAvailableFilters([]),

      nextSearchStart: 0,
      hasMoreUsers: false,
      desiredUserCount: usersPerPage,
      noUsersFound: false,

      usedApplicationByUserId: {},

      downloadedUsersSofar: undefined,
      downloadedUsersCurrentPage: 0,
    },
    Cmd.batch([rolesCmd]),
  ];
}

export function update(action: Action, state: State, activeUser: User.ActiveUser): readonly [State, Cmd<Action>?] {
  switch (action.type) {
    case "ReceivedLoggedInUsersRoles": {
      const roles = action.response && Keycloak.handleGetRolesResponse(action.response);
      const canManageUsers = roles?.some((r) => r.id === clientConfig.keykloak_role_id_manage_users) || false;
      const canViewUsers =
        (roles?.some((r) => r.id === clientConfig.keykloak_role_id_view_users) &&
          roles?.some((r) => r.id === clientConfig.keykloak_role_id_view_clients)) ||
        false;

      const newState = { ...state, canManageUsers, canViewUsers };

      if (!canViewUsers && !canManageUsers) {
        return [newState];
      }

      const fetchAttributesCmd = HttpEffectManager.fetchOne(
        {},
        buildUrl(`/rest/get-attribute-config`),
        "json",
        (data: Keycloak.AttributeConfiguration) => Action.ReceivedAttrbuteConfig(data)
      );

      const usersApiCmd = createSearchUsersCmd(activeUser, state);
      const usersRolesApiCmds = createGetUsersRolesCmds(activeUser);

      const refreshTimerCmd = TimerEffectManager.setTimeout(
        usersRefreshTimerId,
        usersRefreshInterval,
        Action.RefreshUserList
      );

      return [newState, Cmd.batch([fetchAttributesCmd, usersApiCmd, ...usersRolesApiCmds, refreshTimerCmd])];
    }

    case "ReceivedAttrbuteConfig": {
      return [
        {
          ...state,
          attributeConfig: action.attributeConfig,
          availableFilters: getAvailableFilters(action.attributeConfig),
        },
      ];
    }

    case "ReceivedUsers": {
      const response = action.response;
      const receivedUsers = Keycloak.handleGetUsersResponse(response);
      const newUsers = [...(state.users || []), ...receivedUsers];
      const newState = {
        ...state,
        users: newUsers,
        hasMoreUsers: action.hasMoreUsers,
        nextSearchStart: action.nextSearchStart,
        noUsersFound: !action.hasMoreUsers && newUsers.length === 0,
      };
      if (newState.hasMoreUsers && newState.users.length < newState.desiredUserCount) {
        return [newState, createSearchUsersCmd(activeUser, newState)];
      } else {
        return [newState];
      }
    }

    case "ReceivedUsersRole": {
      const users = Keycloak.handleGetUsersResponse(action.response);
      const userIds = new Set(users.map((u) => u.id));
      const newUserRoles = { ...state.userRoles };
      newUserRoles[action.roleName] = userIds;
      return [
        {
          ...state,
          userRoles: newUserRoles,
        },
      ];
    }

    case "GetUserSession": {
      const userSessionCmd = HttpEffectManager.fetchOne(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${action.userId}/sessions`),
        "json",
        (data: Keycloak.GetUsersResponse) => Action.ReceivedUserSesssions(data, action.userId)
      );
      return [state, userSessionCmd];
    }

    case "ReceivedUserSesssions": {
      const sessions = Keycloak.handleGetUserSessionsResponse(action.response);
      const latestSession = sessions?.reduce((latest: Keycloak.UserSessionRepresentation | undefined, session) => {
        if (!session.lastAccess || !session.clients || !session.clients[clientConfig.keycloak_client_application_id]) {
          return latest;
        }
        if (!latest?.lastAccess) {
          return session;
        }
        return session.lastAccess > latest.lastAccess ? session : latest;
      }, undefined);

      const updatedSessions = new Map([...state.userSessions, [action.userId, latestSession]]);

      return [{ ...state, userSessions: updatedSessions }];
    }

    case "UpdateAttribute": {
      if (!state.users) {
        return [state];
      }
      const user = state.users.find((u) => u.id === action.userId);
      if (!user) {
        return [state];
      }
      let updatedAttributes = Keycloak.getApiValue(action.value) as Keycloak.User["attributes"];

      const approvedAttribute = user.attributes[customerNumberApprovedAttributeName];
      const updatedCustomerNumber = updatedAttributes[customerNumberAttributeName];
      if (!!updatedCustomerNumber && approvedAttribute === undefined) {
        updatedAttributes = { ...updatedAttributes, [customerNumberApprovedAttributeName]: [" "] };
      }

      const updatedUsers = state.users.map((u) =>
        u.id === user.id
          ? {
              ...u,
              attributes: {
                ...u.attributes,
                ...updatedAttributes,
              },
            }
          : u
      );
      const cmd = HttpEffectManager.fetchOne(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${user.id}`),
        "json",
        (data: Keycloak.GetUserResponse) =>
          Action.SendUpdatedAttributes(Keycloak.handleGetUserResponse(data), updatedAttributes)
      );
      return [{ ...state, users: updatedUsers }, cmd];
    }

    case "SendUpdatedAttributes": {
      if (!state.users) {
        return [state];
      }
      const remoteUser = action.remoteUser;
      const localUser = state.users.find((u) => u.id === action.remoteUser.id);
      if (!remoteUser || !localUser) {
        return [state];
      }
      const updatedUser = {
        ...remoteUser,
        attributes: {
          ...remoteUser.attributes,
          ...action.updatedAttributes,
        },
      };
      const cmd = HttpEffectManager.put(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${remoteUser.id}`),
        "application/json",
        JSON.stringify(updatedUser)
      );
      return [state, cmd];
    }

    case "UpdateUser": {
      if (!state.users) {
        return [state];
      }
      const user = state.users.find((u) => u.id === action.userId);
      if (!user) {
        return [state];
      }

      const newUser: Keycloak.User = { ...user, ...action.value };

      const updatedUsers = state.users.map((u) => (u.id === user.id ? newUser : u));
      const cmd = HttpEffectManager.fetchOne(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${user.id}`),
        "json",
        (data: Keycloak.GetUserResponse) => Action.SendUpdatedUser(Keycloak.handleGetUserResponse(data), newUser)
      );
      return [{ ...state, users: updatedUsers }, cmd];
    }

    case "SendUpdatedUser": {
      if (!state.users) {
        return [state];
      }
      const remoteUser = action.remoteUser;
      const localUser = state.users.find((u) => u.id === action.remoteUser.id);
      if (!remoteUser || !localUser) {
        return [state];
      }
      const updatedUser = {
        ...remoteUser,
        ...action.updatedUser,
      };
      const cmd = HttpEffectManager.put(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${remoteUser.id}`),
        "application/json",
        JSON.stringify(updatedUser)
      );
      return [state, cmd];
    }

    case "UpdateRole": {
      const viewUserRole = {
        id: clientConfig.keykloak_role_id_view_users,
        name: "view-users",
      };
      const viewClientsRole = {
        id: clientConfig.keykloak_role_id_view_clients,
        name: "view-clients",
      };
      const manageUserRole = {
        id: clientConfig.keykloak_role_id_manage_users,
        name: "manage-users",
      };

      let roles: ReadonlyArray<Keycloak.RoleMappingRepresentation> = [];
      if (action.role === "view-users" && action.hasRole) {
        roles = [viewUserRole, viewClientsRole];
      } else if (action.role === "view-users" && !action.hasRole) {
        roles = [viewUserRole, viewClientsRole, manageUserRole];
      } else if (action.role === "manage-users" && action.hasRole) {
        roles = [viewUserRole, viewClientsRole, manageUserRole];
      } else if (action.role === "manage-users" && !action.hasRole) {
        roles = [manageUserRole];
      }

      const userRoles = { ...state.userRoles };
      for (const role of roles) {
        const oldUserIds = userRoles[role.name];
        const updatedIds = oldUserIds ? new Set(oldUserIds) : new Set<string>();
        if (action.hasRole) {
          updatedIds.add(action.userId);
        } else {
          updatedIds.delete(action.userId);
        }
        userRoles[role.name] = updatedIds;
      }

      const cmd = action.hasRole
        ? HttpEffectManager.post(
            buildAuthHeaders(activeUser),
            buildUrl(
              `/keycloak/users/${action.userId}/role-mappings/clients/${clientConfig.keycloak_client_id_of_client_realm_management_application}`
            ),
            "none",
            "application/json",
            JSON.stringify(roles)
          )
        : HttpEffectManager.deleteOne(
            buildAuthHeaders(activeUser),
            buildUrl(
              `/keycloak/users/${action.userId}/role-mappings/clients/${clientConfig.keycloak_client_id_of_client_realm_management_application}`
            ),
            "application/json",
            JSON.stringify(roles)
          );

      return [{ ...state, userRoles }, cmd];
    }

    case "SendEmailVerification": {
      if (!state.users) {
        return [state];
      }
      const user = state.users.find((u) => u.id === action.userId);
      if (!user) {
        return [state];
      }
      const cmd = HttpEffectManager.put(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${action.userId}/send-verify-email`),
        "application/json",
        ""
      );
      return [state, cmd];
    }

    case "SendLogoutUser": {
      if (!state.users) {
        return [state];
      }
      const user = state.users.find((u) => u.id === action.userId);
      if (!user) {
        return [state];
      }
      const cmd = HttpEffectManager.post(
        buildAuthHeaders(activeUser),
        buildUrl(`/keycloak/users/${action.userId}/logout`),
        "none",
        "application/json",
        "{}",
        () => Action.RemoveUserSession(action.userId)
      );
      return [state, cmd];
    }

    case "RemoveUserSession": {
      const newState: State = {
        ...state,
        userSessions: new Map([...state.userSessions, [action.userId, undefined]]),
      };
      return [newState];
    }

    case "GetMoreUsers": {
      const newState: State = {
        ...state,
        desiredUserCount: state.desiredUserCount + usersPerPage,
      };
      return [newState, createSearchUsersCmd(activeUser, newState)];
    }

    case "ChangeSearchQuery": {
      const newState: State = {
        ...state,
        users: [],
        searchQuery: action.searchQuery,
        nextSearchStart: 0,
        desiredUserCount: usersPerPage,
        hasMoreUsers: false,
        noUsersFound: false,
      };
      return [newState, createSearchUsersCmd(activeUser, newState)];
    }

    case "SetSearchParam": {
      const newState: State = {
        ...state,
        users: [],
        searchParam: action.searchParam,
        nextSearchStart: 0,
        desiredUserCount: usersPerPage,
        hasMoreUsers: false,
        noUsersFound: false,
      };
      return [newState, createSearchUsersCmd(activeUser, newState)];
    }

    case "SetFilter": {
      const newState: State = {
        ...state,
        users: [],
        nextSearchStart: 0,
        desiredUserCount: usersPerPage,
        filter: action.filter,
        hasMoreUsers: false,
        noUsersFound: false,
      };
      return [newState, createSearchUsersCmd(activeUser, newState)];
    }

    case "RefreshUserList": {
      const newState = {
        ...state,
        users: undefined,
        userRoles: {},
        nextSearchStart: 0,
        hasMoreUsers: true,
        noUsersFound: false,
        desiredUserCount: usersPerPage,
      };
      return [
        newState,
        Cmd.batch([
          ...createGetUsersRolesCmds(activeUser),
          createSearchUsersCmd(activeUser, newState),
          TimerEffectManager.setTimeout(usersRefreshTimerId, usersRefreshInterval, Action.RefreshUserList),
        ]),
      ];
    }

    case "GetUsedApplications": {
      if (state.usedApplicationByUserId[action.user.id] === "fetching") {
        return [state];
      }
      const cmd = HttpEffectManager.fetchOne(
        buildAuthHeaders(activeUser),
        buildUrl(`/user-info/used-applications?email=${encodeURIComponent(action.user.email)}`),
        "json",
        (usedApplications: ReadonlyArray<string>) => Action.ReceivedUsedApplications(action.user, usedApplications)
      );
      return [
        {
          ...state,
          usedApplicationByUserId: { ...state.usedApplicationByUserId, [action.user.id]: "fetching" },
        },
        cmd,
      ];
    }

    case "ReceivedUsedApplications": {
      return [
        {
          ...state,
          usedApplicationByUserId: { ...state.usedApplicationByUserId, [action.user.id]: action.usedApplications },
        },
      ];
    }

    case "DownloadUserListInitiate": {
      if (!state.canManageUsers) {
        return [state];
      }
      const newState = {
        ...state,
        downloadedUsersSofar: [],
        downloadedUsersCurrentPage: 0,
      };
      return [newState, createGetUsersCmd(activeUser, newState.downloadedUsersCurrentPage)];
    }

    case "DownloadUserListReceivedUsers": {
      if (!state.downloadedUsersSofar) {
        return [state];
      }
      const receivedUsers = Keycloak.handleGetUsersResponse(action.response);
      if (receivedUsers.length === 0) {
        const newState = {
          ...state,
          downloadedUsersSofar: undefined,
          downloadedUsersCurrentPage: 0,
        };
        const xlsxFile = createXlsxFile(state.attributeConfig, state.userRoles, state.downloadedUsersSofar);
        saveAs(xlsxFile.data, xlsxFile.fileName);
        return [newState];
      } else {
        const newState = {
          ...state,
          downloadedUsersSofar: [...state.downloadedUsersSofar, ...receivedUsers],
          downloadedUsersCurrentPage: state.downloadedUsersCurrentPage + 1,
        };
        return [newState, createGetUsersCmd(activeUser, newState.downloadedUsersCurrentPage)];
      }
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function createGetUsersCmd(activeUser: User.ActiveUser, page: number): Cmd<Action> {
  const usersPerPage = 500;
  const url = `/keycloak/users?first=${page * usersPerPage}&max=${usersPerPage}`;
  const fetchUsersCmd = HttpEffectManager.fetchOne(
    buildAuthHeaders(activeUser),
    buildUrl(url),
    "json",
    Action.DownloadUserListReceivedUsers
  );
  return fetchUsersCmd;
}

export function hasRole(userId: string, role: "view-users" | "manage-users", userRoles: UserRoles): boolean {
  if (role === "view-users") {
    return (
      (!!userRoles["view-users"]?.has(userId) && !!userRoles["view-clients"]?.has(userId)) ||
      !!userRoles["manage-users"]?.has(userId)
    );
  } else {
    return !!userRoles["manage-users"]?.has(userId);
  }
}

function createSearchUsersCmd(activeUser: User.ActiveUser, state: State): Cmd<Action> {
  const { nextSearchStart, searchQuery, searchParam, filter } = state;
  const numUsersToFetch = usersPerPage + 1; // +1 extra user to be able to determine if there is another page
  const url = `/keycloak/users?first=${nextSearchStart}&max=${numUsersToFetch}`;
  const urlWithQuery = appendQueryParam(url, searchQuery, searchParam, filter);
  const fetchUsersCmd = HttpEffectManager.fetchOne(
    buildAuthHeaders(activeUser),
    buildUrl(urlWithQuery),
    "json",
    (data: Keycloak.GetUsersResponse) => {
      const receivedUsers = Array.isArray(data) ? data : [];
      const hasMoreUsers = receivedUsers.length === numUsersToFetch;
      return Action.ReceivedUsers(
        hasMoreUsers ? receivedUsers.slice(0, -1) : receivedUsers,
        hasMoreUsers,
        nextSearchStart + usersPerPage
      );
    }
  );
  return fetchUsersCmd;
}

function appendQueryParam(url: string, searchQuery: string, searchParam: SearchParam, filter: Filter): string {
  let searchUrl = url;
  const queryFilter = mapFilterToQuery(filter);

  if (searchParam.type === "builtin") {
    const querySearchParam =
      ([
        ["email", "email"],
        ["username", "username"],
        ["lastname", "lastName"],
        ["firstname", "firstName"],
      ].find(([param]) => param === searchParam.param) || [])[1] || "email";
    searchUrl += `&${querySearchParam}=${encodeURIComponent(searchQuery)}`;
  }

  const attributeSearch = [
    queryFilter,
    searchParam.type === "attribute"
      ? `${encodeURIComponent(searchParam.attribute)}:${encodeURIComponent(searchQuery)}`
      : "",
  ].filter((f) => !!f);

  if (attributeSearch.length > 0) {
    searchUrl += `&q=${attributeSearch.join(" ")}`;
  }

  return searchUrl;
}

function createGetUsersRolesCmds(activeUser: User.ActiveUser): ReadonlyArray<Cmd<Action>> {
  const roleCmds = ["view-users", "view-clients", "manage-users"].map((roleName) =>
    HttpEffectManager.fetchOne(
      buildAuthHeaders(activeUser),
      buildUrl(
        `/keycloak/clients/${clientConfig.keycloak_client_id_of_client_realm_management_application}/roles/${roleName}/users`
      ),
      "json",
      (data: Keycloak.GetUsersResponse) => Action.ReceivedUsersRole(roleName, data)
    )
  );
  return roleCmds;
}

function buildAuthHeaders(user: User.ActiveUser): Record<string, string> {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${user.accessToken}`,
  };
}

function buildUrl(url: string): string {
  if (clientConfig.server_path_prefix && window.location.hostname !== "localhost") {
    return `/${clientConfig.server_path_prefix}${url}`;
  } else {
    return url;
  }
}

function mapFilterToQuery(filter: Filter): string {
  switch (filter) {
    case "all":
      return "";
    case "approved-users":
      return `${encodeURIComponent(customerNumberApprovedAttributeName)}:true`;
    case "waiting-for-approval":
      return `${encodeURIComponent(customerNumberApprovedAttributeName)}:" "`;
    case "declined-users":
      return `${encodeURIComponent(customerNumberApprovedAttributeName)}:false`;
    default:
      exhaustiveCheck(filter, true);
      return "";
  }
}

function getAvailableFilters(attributeConfig: Keycloak.AttributeConfiguration): ReadonlyArray<Filter> {
  const filters: Array<Filter> = ["all"];

  if (attributeConfig.some((ac) => ac.name === customerNumberApprovedAttributeName)) {
    filters.push("approved-users", "waiting-for-approval", "declined-users");
  }

  return filters;
}
