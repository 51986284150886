import * as React from "react";

export interface CheckboxProps {
  readonly label?: string;
  readonly checked: boolean;
  readonly onChange: (checked: boolean) => void;
  readonly disabled?: boolean;
  readonly className?: string;
}

export function Checkbox(props: CheckboxProps): React.ReactElement<CheckboxProps> {
  let className = "checkbox checkbox-xs mr-2";
  if (props.className) {
    className += ` ${props.className}`;
  }
  const input = (
    <input
      className={className}
      checked={props.checked}
      type="checkbox"
      disabled={props.disabled}
      onChange={() => {
        if (!props.disabled) {
          props.onChange(!props.checked);
        }
      }}
    />
  );
  return props.label ? (
    <label className="flex flex-nowrap items-center">
      {input}
      <span className="whitespace-nowrap">{props.label}</span>
    </label>
  ) : (
    input
  );
}
