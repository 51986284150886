import * as React from "react";
import { withTw } from ".";
import { useClickOutside } from "./use-click-outside";

interface LinkButtonProps {
  readonly active: boolean;
}

const LinkButton = withTw("div", "select-none cursor-pointer", ({ active }: LinkButtonProps) =>
  active ? "text-primary-light" : "text-primary"
);

export interface PopupProps<TContentProps> {
  readonly label: string | React.ReactNode;
  readonly Content: (
    props: TContentProps & { readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }
  ) => JSX.Element;
  readonly contentProps: TContentProps;
}

export function Popup<TContentProps>({
  label,
  contentProps,
  Content,
}: PopupProps<TContentProps>): React.ReactElement<PopupProps<TContentProps>> {
  const [isOpen, setIsOpen] = React.useState(false);
  const popupElement = React.useRef<HTMLDivElement>(null);
  useClickOutside(popupElement, () => setIsOpen(false));
  return (
    <div className="relative inline-block">
      <div onClick={() => setIsOpen(!isOpen)}>
        <div>
          <LinkButton active={isOpen}>
            <div className="whitespace-nowrap text-ellipsis overflow-hidden">{label}</div>
          </LinkButton>
        </div>
      </div>
      {isOpen ? (
        <div
          ref={popupElement}
          className={`z-50 absolute right-1/2 flex flex-col drop-shadow-xl p-4 border rounded-2 border-gray-200 bg-white top-[calc(100%+13px)] translate-x-1/2 
          before:w-[12px] before:h-[12px] before:absolute before:-z-20 before:left-[calc(50%-6px)] before:bg-white before:top-[-7px] before:border before:border-gray-200 before:rotate-45
          after:h-full after:w-full after:rounded-[4px] after:absolute after:top-0 after:left-0 after:-z-10 after:bg-white`}
        >
          <Content {...contentProps} setIsOpen={setIsOpen} />
        </div>
      ) : undefined}
    </div>
  );
}
