import * as React from "react";
import { Select } from "./standard-elements";

export type SelectOption<T> = T & { readonly id: string; readonly name: string };

export interface SelectProps<T> {
  readonly value: SelectOption<T>;
  readonly options: ReadonlyArray<SelectOption<T>>;
  readonly disabled?: boolean;
  readonly errorMessage?: string; // TODO
  readonly onChange: (value: SelectOption<T>) => void;
}

export function Selector<T>(props: SelectProps<T>): React.ReactElement<SelectProps<T>> {
  const { options, value, onChange, disabled } = props;
  const isDisabled = disabled || (options.length === 1 && value?.id === options[0].id);
  return (
    <Select
      value={value?.id || options[0]?.id}
      onChange={(e) => {
        if (!isDisabled) {
          const value = options.find((o) => o.id === e.target.value) || options[0];
          onChange(value);
        }
      }}
      disabled={isDisabled}
    >
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  );
}
