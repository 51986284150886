import * as React from "react";
import { withTw } from "./with-tw";
import { useClickOutside } from "./use-click-outside";
import { Button } from "./button";

interface LinkButtonProps {
  readonly active: boolean;
  readonly disabled?: boolean;
}

const LinkButton = withTw(
  "div",
  "select-none cursor-pointer",
  ({ active }: LinkButtonProps) => (active ? "text-primary-light" : "text-primary")
  // ({ disabled }: LinkButtonProps) => (disabled ? "" : "")
);

const Popup = withTw(
  "div",
  `z-50 absolute right-1/2 flex flex-col drop-shadow-xl border rounded-sm border-gray-200 bg-white top-[calc(100%+13px)] min-w-700 py-2`,
  `before:w-[12px] before:h-[12px] before:absolute before:-z-20 before:bg-white before:top-[-7px] before:border before:border-gray-200 before:rotate-45`,
  `after:h-full after:w-full after:rounded-[4px] after:absolute after:top-0 after:left-0 after:-z-10 after:bg-white`,
  ({ anchorPoint }: { readonly anchorPoint: "left" | "center" | "right" }) =>
    anchorPoint === "center"
      ? `before:left-[calc(50%-6px)] translate-x-1/2`
      : anchorPoint === "left"
      ? `before:left-[6px] translate-x-[-12px]`
      : anchorPoint === "right"
      ? `before:right-[6px] translate-x-[12px]`
      : ""
);

const Option = withTw("div", "cursor-pointer hover:bg-gray-100 py-2 px-3 text-xs");

type Action = {
  readonly label: string | JSX.Element;
  readonly onClick: () => void;
};

export interface DropdownProps {
  readonly actions: ReadonlyArray<Action>;
  readonly anchorPoint?: "left" | "center" | "right";
  readonly onClick?: () => void;
}

export function Dropdown({
  actions,
  onClick,
  anchorPoint = "center",
}: DropdownProps): React.ReactElement<DropdownProps> {
  const [isOpen, setIsOpen] = React.useState(false);
  const popupElement = React.useRef<HTMLDivElement>(null);
  useClickOutside(popupElement, () => setIsOpen(false));

  return (
    <div className="relative inline-block">
      <LinkButton active={isOpen}>
        <Button
          type="secondary"
          label="Action"
          onClick={() => {
            setIsOpen(!isOpen);
            onClick && onClick();
          }}
        />
      </LinkButton>
      {isOpen ? (
        <Popup anchorPoint={anchorPoint}>
          <div ref={popupElement}>
            {actions.map((action, index) => (
              <Option
                key={index}
                onClick={() => {
                  action.onClick();
                  setIsOpen(false);
                }}
              >
                {action.label}
              </Option>
            ))}
            {actions.length === 0 && <span className="py-2 px-3 text-xs">{"No available actions"}</span>}
          </div>
        </Popup>
      ) : undefined}
    </div>
  );
}
